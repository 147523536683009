html {
  background-color: white;
  font-size: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  font-family: Roboto,Helvetica Neue,sans-serif;
  background-color: #f15b2b
}

#root {
  height: 100%;
}

* {
  background-color: transparent;
  border: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font: inherit;
  margin: 0;
  padding: 0
}

#map {
  min-height: 100%;
  min-width: 300px;
  max-height: 100%;
  max-width: 100%;
  padding: 0;
  overflow: hidden;
  position: relative;
}

.google-map-infobox {
  color: #000;
  text-align: center;
}
.google-map-infobox a {
  font-size: 16px;
  text-transform: uppercase;
}

.truncate {
  width: 90%;
  height: 42px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.container {
  height: 100%;
}

.col1 {
  max-height: 100%;
  background-color: #f15b2b;
  overflow-y: scroll;
  overflow-x: hidden;
}

.col2 {
  background-color: #fff;
}

.col3 {
  background-color: #fff;
}

.back-button {
  float: right;
}

.back-button a {
  color: #fff;
  font-size: 22px;
}


.container {
  background-color: #fff;
  color: #fff;
  max-width: 1200px;
  margin: 0 auto;
 /* padding: 0 24px;*/
}

.container:before, .container:after {
  content: " ";
  display: table;
  background-color: #f15b2b;
}

.container:after {
  clear: both
}

.container.container--full {
  max-width: 100%
}

@media screen and (max-width: 1024px) {
  .container {
      padding: 0;
  }
}

.row {
  list-style: none;
  /*margin-left: -24px;*/
  height: 100%;
}

.row:not(.row--flex):before, .row:not(.row--flex):after {
  content: " ";
  display: table
}

.row:not(.row--flex):after {
  clear: both
}

.row.row--gutterless {
  margin-left: 0
}

.row.row--flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap
}

.row.row--flex.items-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.col {
  float: left;
  /*padding-left: 24px;*/
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}

.row--gutterless .col {
  padding-left: 0
}

@media only screen and (min-width: 1025px) {
  h1, .h1 {
      font-size: 42px;
  }
  .heading {
      font-size: 22px;
  }
  .container {
      padding: 0;
  }


  .col--lg-offset-0 {
      margin-left: 0%
  }

  .col--lg-1 {
      width: 8.33333%
  }

  .row--flex .col--lg-1 {
      -ms-flex-preferred-size: 8.33333%;
      flex-basis: 8.33333%;
      max-width: 8.33333%;
      width: auto;
      float: none
  }

  .col--lg-offset-1 {
      margin-left: 8.33333%
  }

  .col--lg-2 {
      width: 16.66667%
  }

  .row--flex .col--lg-2 {
      -ms-flex-preferred-size: 16.66667%;
      flex-basis: 16.66667%;
      max-width: 16.66667%;
      width: auto;
      float: none
  }

  .col--lg-offset-2 {
      margin-left: 16.66667%
  }

  .col--lg-3 {
      width: 25%
  }

  .row--flex .col--lg-3 {
      -ms-flex-preferred-size: 25%;
      flex-basis: 25%;
      max-width: 25%;
      width: auto;
      float: none
  }

  .col--lg-offset-3 {
      margin-left: 25%
  }

  .col--lg-4 {
      width: 33.33333%
  }

  .row--flex .col--lg-4 {
      -ms-flex-preferred-size: 33.33333%;
      flex-basis: 33.33333%;
      max-width: 33.33333%;
      width: auto;
      float: none
  }

  .col--lg-offset-4 {
      margin-left: 33.33333%
  }

  .col--lg-5 {
      width: 41.66667%
  }

  .row--flex .col--lg-5 {
      -ms-flex-preferred-size: 41.66667%;
      flex-basis: 41.66667%;
      max-width: 41.66667%;
      width: auto;
      float: none
  }

  .col--lg-offset-5 {
      margin-left: 41.66667%
  }

  .col--lg-6 {
      width: 50%
  }

  .row--flex .col--lg-6 {
      -ms-flex-preferred-size: 50%;
      flex-basis: 50%;
      max-width: 50%;
      width: auto;
      float: none
  }

  .col--lg-offset-6 {
      margin-left: 50%
  }

  .col--lg-7 {
      width: 58.33333%
  }

  .row--flex .col--lg-7 {
      -ms-flex-preferred-size: 58.33333%;
      flex-basis: 58.33333%;
      max-width: 58.33333%;
      width: auto;
      float: none
  }

  .col--lg-offset-7 {
      margin-left: 58.33333%
  }

  .col--lg-8 {
      width: 66.66667%
  }

  .row--flex .col--lg-8 {
      -ms-flex-preferred-size: 66.66667%;
      flex-basis: 66.66667%;
      max-width: 66.66667%;
      width: auto;
      float: none
  }

  .col--lg-offset-8 {
      margin-left: 66.66667%
  }

  .col--lg-9 {
      width: 75%
  }

  .row--flex .col--lg-9 {
      -ms-flex-preferred-size: 75%;
      flex-basis: 75%;
      max-width: 75%;
      width: auto;
      float: none
  }

  .col--lg-offset-9 {
      margin-left: 75%
  }

  .col--lg-10 {
      width: 83.33333%
  }

  .row--flex .col--lg-10 {
      -ms-flex-preferred-size: 83.33333%;
      flex-basis: 83.33333%;
      max-width: 83.33333%;
      width: auto;
      float: none
  }

  .col--lg-offset-10 {
      margin-left: 83.33333%
  }

  .col--lg-11 {
      width: 91.66667%
  }

  .row--flex .col--lg-11 {
      -ms-flex-preferred-size: 91.66667%;
      flex-basis: 91.66667%;
      max-width: 91.66667%;
      width: auto;
      float: none
  }

  .col--lg-offset-11 {
      margin-left: 91.66667%
  }

  .col--lg-12 {
      width: 100%
  }

  .row--flex .col--lg-12 {
      -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
      max-width: 100%;
      width: auto;
      float: none
  }

  .col--lg-offset-12 {
      margin-left: 100%
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  h1, .h1 {
      font-size: 42px;
  }
  .heading {
      font-size: 22px;
  }
  .container {
      padding: 0;
  }


  .col2 {
      display: none;
  }

  .col--md-offset-0 {
      margin-left: 0%
  }

  .col--md-1 {
      width: 8.33333%
  }

  .row--flex .col--md-1 {
      -ms-flex-preferred-size: 8.33333%;
      flex-basis: 8.33333%;
      max-width: 8.33333%;
      width: auto;
      float: none
  }

  .col--md-offset-1 {
      margin-left: 8.33333%
  }

  .col--md-2 {
      width: 16.66667%
  }

  .row--flex .col--md-2 {
      -ms-flex-preferred-size: 16.66667%;
      flex-basis: 16.66667%;
      max-width: 16.66667%;
      width: auto;
      float: none
  }

  .col--md-offset-2 {
      margin-left: 16.66667%
  }

  .col--md-3 {
      width: 25%
  }

  .row--flex .col--md-3 {
      -ms-flex-preferred-size: 25%;
      flex-basis: 25%;
      max-width: 25%;
      width: auto;
      float: none
  }

  .col--md-offset-3 {
      margin-left: 25%
  }

  .col--md-4 {
      width: 33.33333%
  }

  .row--flex .col--md-4 {
      -ms-flex-preferred-size: 33.33333%;
      flex-basis: 33.33333%;
      max-width: 33.33333%;
      width: auto;
      float: none
  }

  .col--md-offset-4 {
      margin-left: 33.33333%
  }

  .col--md-5 {
      width: 41.66667%
  }

  .row--flex .col--md-5 {
      -ms-flex-preferred-size: 41.66667%;
      flex-basis: 41.66667%;
      max-width: 41.66667%;
      width: auto;
      float: none
  }

  .col--md-offset-5 {
      margin-left: 41.66667%
  }

  .col--md-6 {
      width: 50%
  }

  .row--flex .col--md-6 {
      -ms-flex-preferred-size: 50%;
      flex-basis: 50%;
      max-width: 50%;
      width: auto;
      float: none
  }

  .col--md-offset-6 {
      margin-left: 50%
  }

  .col--md-7 {
      width: 58.33333%
  }

  .row--flex .col--md-7 {
      -ms-flex-preferred-size: 58.33333%;
      flex-basis: 58.33333%;
      max-width: 58.33333%;
      width: auto;
      float: none
  }

  .col--md-offset-7 {
      margin-left: 58.33333%
  }

  .col--md-8 {
      width: 66.66667%
  }

  .row--flex .col--md-8 {
      -ms-flex-preferred-size: 66.66667%;
      flex-basis: 66.66667%;
      max-width: 66.66667%;
      width: auto;
      float: none
  }

  .col--md-offset-8 {
      margin-left: 66.66667%
  }

  .col--md-9 {
      width: 75%
  }

  .row--flex .col--md-9 {
      -ms-flex-preferred-size: 75%;
      flex-basis: 75%;
      max-width: 75%;
      width: auto;
      float: none
  }

  .col--md-offset-9 {
      margin-left: 75%
  }

  .col--md-10 {
      width: 83.33333%
  }

  .row--flex .col--md-10 {
      -ms-flex-preferred-size: 83.33333%;
      flex-basis: 83.33333%;
      max-width: 83.33333%;
      width: auto;
      float: none
  }

  .col--md-offset-10 {
      margin-left: 83.33333%
  }

  .col--md-11 {
      width: 91.66667%
  }

  .row--flex .col--md-11 {
      -ms-flex-preferred-size: 91.66667%;
      flex-basis: 91.66667%;
      max-width: 91.66667%;
      width: auto;
      float: none
  }

  .col--md-offset-11 {
      margin-left: 91.66667%
  }

  .col--md-12 {
      width: 100%
  }

  .row--flex .col--md-12 {
      -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
      max-width: 100%;
      width: auto;
      float: none
  }

  .col--md-offset-12 {
      margin-left: 100%
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  h1, .h1 {
      font-size: 26px;
  }
  .heading {
      font-size: 20px;
  }
  .container {
      padding: 0;
  }

  
  .col2 {
      display: none;
  }

  .col--sm-offset-0 {
      margin-left: 0%
  }

  .col--sm-1 {
      width: 8.33333%
  }

  .row--flex .col--sm-1 {
      -ms-flex-preferred-size: 8.33333%;
      flex-basis: 8.33333%;
      max-width: 8.33333%;
      width: auto;
      float: none
  }

  .col--sm-offset-1 {
      margin-left: 8.33333%
  }

  .col--sm-2 {
      width: 16.66667%
  }

  .row--flex .col--sm-2 {
      -ms-flex-preferred-size: 16.66667%;
      flex-basis: 16.66667%;
      max-width: 16.66667%;
      width: auto;
      float: none
  }

  .col--sm-offset-2 {
      margin-left: 16.66667%
  }

  .col--sm-3 {
      width: 25%
  }

  .row--flex .col--sm-3 {
      -ms-flex-preferred-size: 25%;
      flex-basis: 25%;
      max-width: 25%;
      width: auto;
      float: none
  }

  .col--sm-offset-3 {
      margin-left: 25%
  }

  .col--sm-4 {
      width: 33.33333%
  }

  .row--flex .col--sm-4 {
      -ms-flex-preferred-size: 33.33333%;
      flex-basis: 33.33333%;
      max-width: 33.33333%;
      width: auto;
      float: none
  }

  .col--sm-offset-4 {
      margin-left: 33.33333%
  }

  .col--sm-5 {
      width: 41.66667%
  }

  .row--flex .col--sm-5 {
      -ms-flex-preferred-size: 41.66667%;
      flex-basis: 41.66667%;
      max-width: 41.66667%;
      width: auto;
      float: none
  }

  .col--sm-offset-5 {
      margin-left: 41.66667%
  }

  .col--sm-6 {
      width: 50%
  }

  .row--flex .col--sm-6 {
      -ms-flex-preferred-size: 50%;
      flex-basis: 50%;
      max-width: 50%;
      width: auto;
      float: none
  }

  .col--sm-offset-6 {
      margin-left: 50%
  }

  .col--sm-7 {
      width: 58.33333%
  }

  .row--flex .col--sm-7 {
      -ms-flex-preferred-size: 58.33333%;
      flex-basis: 58.33333%;
      max-width: 58.33333%;
      width: auto;
      float: none
  }

  .col--sm-offset-7 {
      margin-left: 58.33333%
  }

  .col--sm-8 {
      width: 66.66667%
  }

  .row--flex .col--sm-8 {
      -ms-flex-preferred-size: 66.66667%;
      flex-basis: 66.66667%;
      max-width: 66.66667%;
      width: auto;
      float: none
  }

  .col--sm-offset-8 {
      margin-left: 66.66667%
  }

  .col--sm-9 {
      width: 75%
  }

  .row--flex .col--sm-9 {
      -ms-flex-preferred-size: 75%;
      flex-basis: 75%;
      max-width: 75%;
      width: auto;
      float: none
  }

  .col--sm-offset-9 {
      margin-left: 75%
  }

  .col--sm-10 {
      width: 83.33333%
  }

  .row--flex .col--sm-10 {
      -ms-flex-preferred-size: 83.33333%;
      flex-basis: 83.33333%;
      max-width: 83.33333%;
      width: auto;
      float: none
  }

  .col--sm-offset-10 {
      margin-left: 83.33333%
  }

  .col--sm-11 {
      width: 91.66667%
  }

  .row--flex .col--sm-11 {
      -ms-flex-preferred-size: 91.66667%;
      flex-basis: 91.66667%;
      max-width: 91.66667%;
      width: auto;
      float: none
  }

  .col--sm-offset-11 {
      margin-left: 91.66667%
  }

  .col--sm-12 {
      width: 100%
  }

  .row--flex .col--sm-12 {
      -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
      max-width: 100%;
      width: auto;
      float: none
  }

  .col--sm-offset-12 {
      margin-left: 100%
  }
}

@media only screen and (max-width: 480px) {
  h1, .h1 {
      font-size: 26px;
  }
  .heading {
      font-size: 20px;
  }
  .container {
      padding: 0;
  }

  .col2 {
      display: none;
  }

  .col--xs-offset-0 {
      margin-left: 0%
  }

  .col--xs-1 {
      width: 8.33333%
  }

  .row--flex .col--xs-1 {
      -ms-flex-preferred-size: 8.33333%;
      flex-basis: 8.33333%;
      max-width: 8.33333%;
      width: auto;
      float: none
  }

  .col--xs-offset-1 {
      margin-left: 8.33333%
  }

  .col--xs-2 {
      width: 16.66667%
  }

  .row--flex .col--xs-2 {
      -ms-flex-preferred-size: 16.66667%;
      flex-basis: 16.66667%;
      max-width: 16.66667%;
      width: auto;
      float: none
  }

  .col--xs-offset-2 {
      margin-left: 16.66667%
  }

  .col--xs-3 {
      width: 25%
  }

  .row--flex .col--xs-3 {
      -ms-flex-preferred-size: 25%;
      flex-basis: 25%;
      max-width: 25%;
      width: auto;
      float: none
  }

  .col--xs-offset-3 {
      margin-left: 25%
  }

  .col--xs-4 {
      width: 33.33333%
  }

  .row--flex .col--xs-4 {
      -ms-flex-preferred-size: 33.33333%;
      flex-basis: 33.33333%;
      max-width: 33.33333%;
      width: auto;
      float: none
  }

  .col--xs-offset-4 {
      margin-left: 33.33333%
  }

  .col--xs-5 {
      width: 41.66667%
  }

  .row--flex .col--xs-5 {
      -ms-flex-preferred-size: 41.66667%;
      flex-basis: 41.66667%;
      max-width: 41.66667%;
      width: auto;
      float: none
  }

  .col--xs-offset-5 {
      margin-left: 41.66667%
  }

  .col--xs-6 {
      width: 50%
  }

  .row--flex .col--xs-6 {
      -ms-flex-preferred-size: 50%;
      flex-basis: 50%;
      max-width: 50%;
      width: auto;
      float: none
  }

  .col--xs-offset-6 {
      margin-left: 50%
  }

  .col--xs-7 {
      width: 58.33333%
  }

  .row--flex .col--xs-7 {
      -ms-flex-preferred-size: 58.33333%;
      flex-basis: 58.33333%;
      max-width: 58.33333%;
      width: auto;
      float: none
  }

  .col--xs-offset-7 {
      margin-left: 58.33333%
  }

  .col--xs-8 {
      width: 66.66667%
  }

  .row--flex .col--xs-8 {
      -ms-flex-preferred-size: 66.66667%;
      flex-basis: 66.66667%;
      max-width: 66.66667%;
      width: auto;
      float: none
  }

  .col--xs-offset-8 {
      margin-left: 66.66667%
  }

  .col--xs-9 {
      width: 75%
  }

  .row--flex .col--xs-9 {
      -ms-flex-preferred-size: 75%;
      flex-basis: 75%;
      max-width: 75%;
      width: auto;
      float: none
  }

  .col--xs-offset-9 {
      margin-left: 75%
  }

  .col--xs-10 {
      width: 83.33333%
  }

  .row--flex .col--xs-10 {
      -ms-flex-preferred-size: 83.33333%;
      flex-basis: 83.33333%;
      max-width: 83.33333%;
      width: auto;
      float: none
  }

  .col--xs-offset-10 {
      margin-left: 83.33333%
  }

  .col--xs-11 {
      width: 91.66667%
  }

  .row--flex .col--xs-11 {
      -ms-flex-preferred-size: 91.66667%;
      flex-basis: 91.66667%;
      max-width: 91.66667%;
      width: auto;
      float: none
  }

  .col--xs-offset-11 {
      margin-left: 91.66667%
  }

  .col--xs-12 {
      width: 100%
  }

  .row--flex .col--xs-12 {
      -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
      max-width: 100%;
      width: auto;
      float: none
  }

  .col--xs-offset-12 {
      margin-left: 100%
  }
}



input, select, textarea, button {
  color: inherit;
  display: inline-block
}

input[disabled], select[disabled], textarea[disabled], button[disabled] {
  cursor: not-allowed
}

button {
  cursor: pointer
}

table {
  border-collapse: collapse;
  border-spacing: 0
}

table th, table td {
  text-align: left
}

.visible-lg {
  display: none
}

@media only screen and (min-width: 1025px) {
  .visible-lg {
      display: block !important
  }
}

@media only screen and (min-width: 1025px) {
  .hidden-lg {
      display: none
  }
}

.visible-md {
  display: none
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .visible-md {
      display: block !important
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .hidden-md {
      display: none
  }
}

.visible-sm {
  display: none
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .visible-sm {
      display: block !important
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .hidden-sm {
      display: none
  }
}

.visible-xs {
  display: none
}

@media only screen and (max-width: 480px) {
  .visible-xs {
      display: block !important
  }
}

@media only screen and (max-width: 480px) {
  .hidden-xs {
      display: none
  }
}

.alignleft, .float-left {
  float: left
}

img .alignleft, img .float-left {
  margin-right: 24px
}

.alignright, .float-right {
  float: right
}

img .alignright, img .float-right {
  margin-left: 24px
}

.aligncenter, .float-center {
  display: block;
  margin-left: auto;
  margin-right: auto
}

img .aligncenter, img .float-center {
  margin-bottom: 24px
}

.clearfix:before, .clearfix:after {
  content: " ";
  display: table
}

.clearfix:after {
  clear: both
}

.align-text-left {
  text-align: left
}

.align-text-right {
  text-align: right
}

.align-text-center {
  text-align: center
}

.visible-on-mobile {
  display: none !important
}

@media screen and (max-width: 768px) {
  .visible-on-mobile {
      display: block !important
  }
}

.visible-on-tablet {
  display: none !important
}

@media screen and (max-width: 1024px) {
  .visible-on-tablet {
      display: block !important
  }
}

@media screen and (max-width: 768px) {
  .visible-on-tablet {
      display: none !important
  }
}

@media screen and (max-width: 1024px) {
  .visible-on-desktop {
      display: none !important
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0
}

.bg-primary {
  background-color: #F2B32B
}

.bg-secondary {
  background-color: #BF6046
}

.color-primary {
  color: #F2B32B
}

.color-secondary {
  color: #BF6046
}

.color-white {
  color: #fff
}

.color-blue {
  color: #273240
}

.uppercase {
  text-transform: uppercase
}

.block {
  display: block
}

.relative {
  position: relative
}

.h-full {
  height: 100%
}

.p-base {
  padding: 24px
}

.py-base {
  padding: 24px 0
}

.py-half {
  padding: 12px
}

.max-width-content {
  max-width: 600px;
  margin: auto
}

@-webkit-keyframes spin {
  from {
      -webkit-transform: translateY(-50%) rotate(0deg);
      transform: translateY(-50%) rotate(0deg)
  }

  to {
      -webkit-transform: translateY(-50%) rotate(360deg);
      transform: translateY(-50%) rotate(360deg)
  }
}

@keyframes spin {
  from {
      -webkit-transform: translateY(-50%) rotate(0deg);
      transform: translateY(-50%) rotate(0deg)
  }

  to {
      -webkit-transform: translateY(-50%) rotate(360deg);
      transform: translateY(-50%) rotate(360deg)
  }
}

html {
  scroll-behavior: smooth
}

body {
  color: #444;
  line-height: 1.5;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
  font-size: 1rem;
}

h1, h2, h3, h4, h5, h6, p, hr, ul, ol, dl {
  margin-bottom: 24px
}

h1:last-child, h2:last-child, h3:last-child, h4:last-child, h5:last-child, h6:last-child, p:last-child, hr:last-child, ul:last-child, ol:last-child, dl:last-child {
  margin-bottom: 0
}

a {
  color: #F2B32B;
  text-decoration: none
}

a:hover {
  color: #000;
  text-decoration: underline
}

a[href^="tel"] {
  font-size: inherit;
  font-family: inherit;
  color: inherit;
  text-decoration: none;
  font-style: inherit
}

hr {
  height: 1px;
  background-color: #444
}

.grid {
  display: grid;
  grid-gap: 24px
}

@media screen and (max-width: 768px) {
  .grid {
      grid-gap: 12px
  }
}

@media only screen and (min-width: 1025px) {
  .grid.grid--lg-1x {
      grid-template-columns: repeat(1, minmax(0, 1fr))
  }

  .grid.grid--lg-2x {
      grid-template-columns: repeat(2, minmax(0, 1fr))
  }

  .grid.grid--lg-3x {
      grid-template-columns: repeat(3, minmax(0, 1fr))
  }

  .grid.grid--lg-4x {
      grid-template-columns: repeat(4, minmax(0, 1fr))
  }

  .grid.grid--lg-5x {
      grid-template-columns: repeat(5, minmax(0, 1fr))
  }

  .grid.grid--lg-6x {
      grid-template-columns: repeat(6, minmax(0, 1fr))
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .grid.grid--md-1x {
      grid-template-columns: repeat(1, minmax(0, 1fr))
  }

  .grid.grid--md-2x {
      grid-template-columns: repeat(2, minmax(0, 1fr))
  }

  .grid.grid--md-3x {
      grid-template-columns: repeat(3, minmax(0, 1fr))
  }

  .grid.grid--md-4x {
      grid-template-columns: repeat(4, minmax(0, 1fr))
  }

  .grid.grid--md-5x {
      grid-template-columns: repeat(5, minmax(0, 1fr))
  }

  .grid.grid--md-6x {
      grid-template-columns: repeat(6, minmax(0, 1fr))
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .grid.grid--sm-1x {
      grid-template-columns: repeat(1, minmax(0, 1fr))
  }

  .grid.grid--sm-2x {
      grid-template-columns: repeat(2, minmax(0, 1fr))
  }

  .grid.grid--sm-3x {
      grid-template-columns: repeat(3, minmax(0, 1fr))
  }

  .grid.grid--sm-4x {
      grid-template-columns: repeat(4, minmax(0, 1fr))
  }

  .grid.grid--sm-5x {
      grid-template-columns: repeat(5, minmax(0, 1fr))
  }

  .grid.grid--sm-6x {
      grid-template-columns: repeat(6, minmax(0, 1fr))
  }
}

@media only screen and (max-width: 480px) {
  .grid.grid--xs-1x {
      grid-template-columns: repeat(1, minmax(0, 1fr))
  }

  .grid.grid--xs-2x {
      grid-template-columns: repeat(2, minmax(0, 1fr))
  }

  .grid.grid--xs-3x {
      grid-template-columns: repeat(3, minmax(0, 1fr))
  }

  .grid.grid--xs-4x {
      grid-template-columns: repeat(4, minmax(0, 1fr))
  }

  .grid.grid--xs-5x {
      grid-template-columns: repeat(5, minmax(0, 1fr))
  }

  .grid.grid--xs-6x {
      grid-template-columns: repeat(6, minmax(0, 1fr))
  }
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: top
}

.is-fluid {
  display: block;
  width: 100%;
  height: auto
}

*:focus {
  outline: 0
}

input, select, textarea {
  background-color: #fff;
  border: solid 1px #444;
  -webkit-border-radius: 0;
  border-radius: 0;
  color: #444;
  line-height: 36px;
  height: 36px;
  padding: 0 12px;
  width: 100%
}

input[type="checkbox"], input[type="radio"] {
  background: transparent;
  border: 0;
  line-height: normal;
  height: auto;
  width: auto
}

input[type="file"] {
  line-height: normal;
  height: auto;
  padding: 6px 12px
}

input[type="submit"] {
  -webkit-border-radius: 0;
  border-radius: 0
}

input[disabled] {
  background-color: whitesmoke;
  cursor: not-allowed
}

select {
  line-height: normal;
  padding: 0;
  padding-left: 12px
}

textarea {
  min-height: 96px;
  overflow: auto;
  vertical-align: top;
  resize: vertical
}

input[type="text"] {
  border: none;
  font-size: 14px;
  font-size: .875rem
}

input[type="text"]::-webkit-input-placeholder {
  text-transform: uppercase
}

input[type="text"]::-moz-placeholder {
  text-transform: uppercase
}

input[type="text"]:-moz-placeholder {
  text-transform: uppercase
}

input[type="text"]:-ms-input-placeholder {
  text-transform: uppercase
}

.form--inline .form__group {
  display: inline-block
}

.form .form__group {
  display: block;
  margin-bottom: 24px
}

.form .form__group .form__label {
  display: block;
  margin-bottom: 12px
}

.form .search-form {
  position: relative;
  background-color: #fff
}

.form .search-form .animate-spin {
  display: none;
  width: 20px;
  height: 20px
}

.form .search-form .loading {
  display: none;
  position: absolute;
  width: 20px;
  height: 20px;
  top: 50%;
  right: 12px;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  -webkit-animation-name: spin;
  animation-name: spin;
  -webkit-animation-duration: 1000ms;
  animation-duration: 1000ms;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear
}

.form .search-form .loading.active {
  display: block
}

.form .search-form .loading svg {
  display: block
}

ul, ol {
  margin-left: 24px
}

li > ul, li > ol {
  margin-bottom: 0
}

.list--unstyled, .list--social, .list--spaces {
  margin-left: 0;
  list-style: none
}

.list--social {
  margin: 24px 0;
  display: flex;
  justify-content: center
}

  .list--social li {
      display: inline-block;
      margin: 0 auto
  }

.list--spaces {
  color: #fff;
  /*max-height: 650px;*/
  overflow: auto;
  margin-top: 12px
}

  /*.list--spaces::-webkit-scrollbar {
      display: none;
      -ms-overflow-style: none;
      scrollbar-width: none
  }*/

  .list--spaces li {
      position: relative;
      margin: 30px 0 0 0
  }


      .list--spaces li a {
          display: block;
          color: #fff;
      }

          .list--spaces li a:hover {
              text-decoration: none;
          }

      .list--spaces li:after {
          display: block;
          position: absolute;
          content: '';
          width: 20px;
          height: 25px;
          right: 0;
          top: 50%;
          -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          transform: translateY(-50%);
          background-image: url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2233.937%22%20height%3D%2241.051%22%20viewBox%3D%220%200%2033.937%2041.051%22%3E%20%3Cpath%20id%3D%22Path_5471%22%20data-name%3D%22Path%205471%22%20d%3D%22M1182.579%2C478.807l9.523-12.941%2C9.459%2C12.888M1193%2C458.281h-1.854L1175.1%2C478.807l16.169%2C20.525h1.791l15.978-20.525Z%22%20transform%3D%22translate%281209.038%20499.332%29%20rotate%28180%29%22%20fill%3D%22%23fff%22%2F%3E%20%3C%2Fsvg%3E");
          -webkit-background-size: contain;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          pointer-events: none
      }

@media screen and (max-width: 1024px) {
  .list--spaces li {
      padding-right: 48px
  }
}

.list--partners {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  list-style: none;
  margin: auto
}

  .list--partners li {
      padding: 0 12px
  }

h1, h2, h3, h4, h5, h6, strong {
  font-weight: bold
}

em {
  font-style: italic
}

small {
  font-size: .75em
}

h1, .h1 {
/*    font-size: 42px;
  font-size: 2.625rem;*/
  text-transform: uppercase;
  margin-bottom: 6px;
  /*font-weight: 500;*/
  letter-spacing: 3px;
  line-height: 1.1em
}

.p {
  font-size: 16px;
  font-size: 1rem
}

.text-medium {
  font-size: 22px;
  font-size: 1.375rem
}

.text-large {
  font-size: 26px;
  font-size: 1.625rem
}

.text-xl {
  font-size: 60px;
  font-size: 3.75rem
}

@media screen and (max-width: 1024px) {
  .text-xl {
      font-size: 30px;
      font-size: 1.875rem
  }
}

.strong {
  font-weight: bold
}

.italic {
  font-size: italic
}

.flush {
  margin: 0
}

.content-diamond {
  display: block;
  position: absolute;
  content: '';
  width: 30px;
  height: 35px;
  left: 0;
  -webkit-transform: translate(-150%, 0);
  -ms-transform: translate(-150%, 0);
  transform: translate(-150%, 0);
  background-image: url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2255.437%22%20height%3D%2267.057%22%20viewBox%3D%220%200%2055.437%2067.057%22%3E%20%3Cpath%20id%3D%22Path_5396%22%20data-name%3D%22Path%205396%22%20d%3D%22M1187.317%2C491.81l15.556-21.139%2C15.451%2C21.052m-13.99-33.442h-3.028l-26.2%2C33.529%2C26.412%2C33.528h2.925l26.1-33.528Z%22%20transform%3D%22translate%281230.538%20525.338%29%20rotate%28180%29%22%20fill%3D%22%23273140%22%2F%3E%20%3C%2Fsvg%3E");
  -webkit-background-size: contain;
  background-size: contain;
  background-repeat: no-repeat
}

@media screen and (max-width: 1024px) {
  .content-diamond {
      display: none
  }
}


body.home, body.single-space {
  padding-top: 0
}


.section--brand {
  background-color: #F2B32B
}

.section--header {
  position: relative;
  height: 600px;
  padding: 0
}

@media screen and (max-width: 1024px) {
  .home .section--header {
      height: 405px
  }
}

@media screen and (max-width: 480px) {
  .home .section--header {
      height: auto;
      padding-top: 84px
  }

      .home .section--header > div {
          -webkit-background-size: contain;
          background-size: contain;
          background-repeat: no-repeat
      }
}

.single-space .section--header {
  height: 600px
}

@media screen and (max-width: 1024px) {
  .single-space .section--header {
      height: 405px
  }
}

@media screen and (max-width: 480px) {
  .single-space .section--header {
      height: 254px;
      padding-top: 84px
  }
}

.section--header > div {
  height: 100%;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center
}

.section--header:before {
  display: block;
  position: absolute;
  content: '';
  background-color: rgba(0,0,0,0.2);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1
}

.section--header:after {
  display: block;
  position: absolute;
  content: '';
  width: 55px;
  height: 67px;
  left: 50%;
  bottom: 0;
  -webkit-transform: translate(-50%, 50%);
  -ms-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%);
  -webkit-background-size: contain;
  background-size: contain;
  /* background-image: url("img/diamond.svg"); */
  z-index: 1
}

.section--header .slick-list, .section--header .slick-track, .section--header .slick-slide > div, .section--header .slide__content {
  height: 100%;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center
}

.section--header .slide__content {
  position: relative;
  padding-top: 105px
}

  .section--header .slide__content:before {
      display: block;
      position: absolute;
      content: '';
      background-color: rgba(0,0,0,0.2);
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1
  }

  .section--header .slide__content .container {
      position: relative;
      z-index: 2
  }

@media screen and (max-width: 1024px) {
  .section--header .slide__content {
      padding-top: 85px;
      min-height: 170px
  }
}

.section--header .slick-arrow {
  position: absolute
}

.section--header .slide {
  height: 100%;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
  color: #F2B32B
}

  .section--header .slide .container {
      height: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center
  }

@media screen and (max-width: 1024px) {
  .section--header {
      height: 300px
  }
}

.home .section--header:before {
  display: none
}

.section--title {
  padding: 48px 0
}

.section--padded {
  padding: 48px 0
}

.section--halfandhalf {
  background-color: #F2B32B;
  background: -webkit-gradient(linear, left top, right top, color-stop(50%, #F2B32B), color-stop(50%, #fff));
  background: -webkit-linear-gradient(left, #F2B32B 50%, #fff 50%);
  background: -o-linear-gradient(left, #F2B32B 50%, #fff 50%);
  background: linear-gradient(90deg, #F2B32B 50%, #fff 50%)
}

@media screen and (max-width: 1024px) {
  .section--halfandhalf {
      background: transparent
  }

      .section--halfandhalf .space__content {
          position: relative;
          background-color: #F2B32B
      }

          .section--halfandhalf .space__content:after {
              display: block;
              position: absolute;
              content: '';
              background-color: #F2B32B;
              width: 100vw;
              height: 100%;
              top: 0;
              left: -24px;
              z-index: -1
          }
}

.section--halfandhalf.section--no-hero {
  padding-top: 105px
}

@media screen and (max-width: 1024px) {
  .section--halfandhalf.section--no-hero {
      padding-top: 85px
  }
}

.section--primarycolours {
  padding: 96px 0;
  background-color: #F2B32B;
  background: -webkit-gradient(linear, left top, right top, color-stop(50%, #BF6046), color-stop(50%, #F2B32B));
  background: -webkit-linear-gradient(left, #BF6046 50%, #F2B32B 50%);
  background: -o-linear-gradient(left, #BF6046 50%, #F2B32B 50%);
  background: linear-gradient(90deg, #BF6046 50%, #F2B32B 50%)
}

  .section--primarycolours .gallery {
      min-height: 600px
  }

  .section--primarycolours .content {
      padding: 60px
  }

      .section--primarycolours .content a {
          color: #273240
      }

  .section--primarycolours .gallery {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      overflow: hidden
  }

      .section--primarycolours .gallery img {
          position: absolute
      }

          .section--primarycolours .gallery img:nth-child(1) {
              top: 48px;
              left: -72px;
              max-width: 300px;
              z-index: 1
          }

          .section--primarycolours .gallery img:nth-child(2) {
              max-width: 500px;
              top: 0;
              right: 0
          }

          .section--primarycolours .gallery img:nth-child(3) {
              left: 24px;
              bottom: -48px;
              max-width: 250px;
              z-index: 1
          }

          .section--primarycolours .gallery img:nth-child(4) {
              right: -48px;
              bottom: 0;
              max-width: 300px;
              z-index: 1
          }

@media screen and (max-width: 1024px) {
  .section--primarycolours {
      background: #BF6046;
      padding-bottom: 0;
      padding-top: 48px
  }

      .section--primarycolours .gallery {
          position: relative;
          background-color: #F2B32B;
          padding: 0;
          margin: 0;
          min-height: 1px;
          margin-top: 48px
      }

          .section--primarycolours .gallery:after {
              display: block;
              position: absolute;
              content: '';
              background-color: #F2B32B;
              width: 100vw;
              height: 100%;
              top: 0;
              left: -24px;
              z-index: 0
          }

          .section--primarycolours .gallery img:nth-child(2) {
              position: relative;
              top: auto;
              left: auto;
              right: auto;
              bottom: auto;
              z-index: 1;
              width: calc(100% + 48px)
          }

          .section--primarycolours .gallery img:nth-child(1), .section--primarycolours .gallery img:nth-child(3), .section--primarycolours .gallery img:nth-child(4) {
              display: none
          }
}

.section--textimage {
  position: relative;
  padding: 96px 0;
  background-color: #F2B32B;
  background-position: right
}

  .section--textimage.section--textimage--white {
      background: -webkit-gradient(linear, left top, right top, color-stop(50%, #fff), color-stop(50%, #F2B32B));
      background: -webkit-linear-gradient(left, #fff 50%, #F2B32B 50%);
      background: -o-linear-gradient(left, #fff 50%, #F2B32B 50%);
      background: linear-gradient(90deg, #fff 50%, #F2B32B 50%)
  }

@media screen and (max-width: 1024px) {
  .section--textimage.section--textimage--white {
      background: #F2B32B !important;
      padding-bottom: 48px
  }
}

@media screen and (max-width: 1024px) {
  .section--textimage {
      padding-bottom: 0
  }
}

.section--textimage .max-width-content p {
  position: relative
}

.section--textimage .background {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 50%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center
}

  .section--textimage .background img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover
  }

@media screen and (max-width: 1024px) {
  .section--textimage .background {
      position: relative;
      left: 0;
      margin-top: 48px
  }
}

.section--textimage .content {
  padding: 0 60px 0 0
}

@media screen and (max-width: 1024px) {
  .section--textimage .content {
      padding-right: 0
  }
}

.section--talknow {
  background-color: #BF6046;
  color: #fff
}

  .section--talknow a {
      color: #273240
  }

.space__content__content {
  position: relative;
  margin: auto;
  padding: 96px 0
}

  .space__content__content:before {
      display: none;
      position: absolute;
      content: '';
      width: 41px;
      height: 50px;
      left: 0;
      top: 96px;
      -webkit-transform: translate(-150%, 0);
      -ms-transform: translate(-150%, 0);
      transform: translate(-150%, 0);
      background-image: url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2255.437%22%20height%3D%2267.057%22%20viewBox%3D%220%200%2055.437%2067.057%22%3E%20%3Cpath%20id%3D%22Path_5396%22%20data-name%3D%22Path%205396%22%20d%3D%22M1187.317%2C491.81l15.556-21.139%2C15.451%2C21.052m-13.99-33.442h-3.028l-26.2%2C33.529%2C26.412%2C33.528h2.925l26.1-33.528Z%22%20transform%3D%22translate%281230.538%20525.338%29%20rotate%28180%29%22%20fill%3D%22%23273140%22%2F%3E%20%3C%2Fsvg%3E");
      -webkit-background-size: contain;
      background-size: contain
  }

@media screen and (max-width: 1024px) {
  .space__content__content:before {
      display: none
  }
}

.space__contact {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  max-width: 300px;
  min-height: 600px;
  margin: auto
}

  .space__contact img {
      margin: 0 0 48px 0
  }

  .space__contact a {
      color: #BF6046
  }

@media screen and (max-width: 1024px) {
  .space__contact {
      margin-bottom: 48px;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      word-break: break-all
  }
}

.section--map {
  padding: 0 24px 48px 24px
}

  .section--map [data-reveal] {
      color: #273240;
      cursor: default;
      display: block
  }

@media screen and (max-width: 768px) {
  .section--map [data-reveal] {
      margin-top: 24px;
      cursor: pointer
  }

      .section--map [data-reveal].active {
          background-color: #BF6046;
          color: #fff
      }
}

.section--map [data-reveal]:hover {
  text-decoration: none
}

.section--talknow {
  padding: 48px 0
}

.section--cta {
  padding: 48px 0
}

  .section--cta .section--cta__buttons {
      font-size: 0px;
      font-size: 0rem
  }

.google-map {
  width: 100%;
  height: 750px
}

.video-container iframe {
  width: 100%
}

.video-thumb {
  position: relative;
  margin-bottom: 24px
}

  .video-thumb:hover:after {
      opacity: 0.65
  }

  .video-thumb:after {
      position: absolute;
      content: '';
      width: 50px;
      height: 50px;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      /* background-image: url("img/play.svg"); */
      -webkit-background-size: contain;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      -webkit-transition: opacity 250ms;
      -o-transition: opacity 250ms;
      transition: opacity 250ms;
      pointer-events: none
  }

@media screen and (max-width: 1024px) {
  .video {
      margin-bottom: 48px
  }

      .video:last-child {
          margin-bottom: 0
      }
}

.section--partners {
  padding: 24px 0
}

.header {
  position: fixed;
  width: 100%;
  padding: 24px 0;
  top: 0;
  z-index: 2;
  background-color: rgba(191,96,70,0.95)
}

.home .header {
  background-color: rgba(156,76,54,0.95)
}

.header img {
  -webkit-transition: max-height 250ms;
  -o-transition: max-height 250ms;
  transition: max-height 250ms;
  max-height: 60px
}

@media screen and (min-width: 1280px) {
  .header.not-top img {
      max-height: 25px
  }
}

.footer {
  padding: 24px 0;
  background-color: #BF6046;
  color: #fff
}

  .footer a {
      color: #fff
  }

.section--partners {
  background-color: #fff
}

@media screen and (max-width: 1024px) {
  .white__logo {
      margin-top: 48px
  }
}

@media screen and (max-width: 768px) {
  .footer__content {
      text-align: left
  }
}

.footer__copyright a {
  text-decoration: underline
}

  .footer__copyright a:hover {
      text-decoration: none
  }

.btn {
  background-color: #F2B32B;
  color: #fff;
  border: 1px solid transparent;
  display: inline-block;
  padding: 6px 24px;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: auto;
  -webkit-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s
}

  .btn::-moz-focus-inner {
      border: 0;
      padding: 0
  }

  .btn:hover {
      text-decoration: none;
      background-color: #fff;
      color: #F2B32B;
      border-color: #F2B32B
  }

  .btn.is-disabled, .btn:disabled {
      pointer-events: none;
      opacity: .65
  }

.btn--inverse {
  background-color: #fff;
  color: #F2B32B;
  border: 1px solid #F2B32B
}

  .btn--inverse:hover {
      background-color: #F2B32B;
      color: #fff;
      border-color: transparent
  }

.btn--block {
  display: block;
  width: 100%
}

  .btn--block + .btn--block {
      margin-top: 6px
  }

.btn--link {
  background-color: transparent;
  color: #F2B32B
}

.btn--large {
  text-transform: uppercase;
  background-color: transparent;
  color: #BF6046;
  border-color: #BF6046;
  min-width: 350px;
  padding: 18px;
  font-size: 28px;
  font-size: 1.75rem;
  letter-spacing: 3px
}

  .btn--large:hover {
      background-color: #BF6046;
      color: #fff;
      border-color: #BF6046
  }

@media screen and (max-width: 480px) {
  .btn--large {
      min-width: 50%;
      white-space: normal;
      font-size: 11px;
      font-size: .6875rem;
      padding: 12px 6px;
      letter-spacing: 1px
  }
}

.nav-burger {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 24px;
  z-index: 550;
  -webkit-transition: all 250ms ease-in-out;
  -o-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out
}

.nav-burger__line {
  width: 35px;
  height: 3px;
  background-color: #F2B32B;
  display: none;
  margin: 8px auto;
  -webkit-transition: all 250ms ease-in-out;
  -o-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out
}

@media screen and (max-width: 1024px) {
  .nav-burger__line {
      display: block
  }
}

.nav-burger.open .nav-burger__line:nth-child(2) {
  opacity: 0
}

.nav-burger.open .nav-burger__line:nth-child(1) {
  -webkit-transform: translateY(11px) rotate(45deg);
  -ms-transform: translateY(11px) rotate(45deg);
  transform: translateY(11px) rotate(45deg)
}

.nav-burger.open .nav-burger__line:nth-child(3) {
  -webkit-transform: translateY(-11px) rotate(-45deg);
  -ms-transform: translateY(-11px) rotate(-45deg);
  transform: translateY(-11px) rotate(-45deg)
}

@media screen and (max-width: 1024px) {
  .header__navigation {
      display: none;
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: #BF6046
  }
}

.nav {
  list-style: none;
  margin: 0
}

  .nav > li {
      display: inline-block;
      margin-right: 24px
  }

      .nav > li:last-child {
          margin-right: 0
      }

      .nav > li > a {
          display: block
      }

.nav--header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-transform: uppercase;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between
}

  .nav--header a {
      color: #fff;
      font-size: 16px;
      font-size: 1rem;
      letter-spacing: 4px;
      -webkit-transition: color 250ms;
      -o-transition: color 250ms;
      transition: color 250ms
  }

      .nav--header a:hover {
          text-decoration: none;
          color: #273240
      }

@media screen and (max-width: 1024px) {
  .nav--header a {
      color: #fff
  }
}

.nav--header .current-menu-item a {
  color: #273240
}

@media screen and (max-width: 1024px) {
  .nav--header {
      width: 100%;
      position: fixed;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      top: 0;
      left: 50%;
      height: 100%;
      margin: 0;
      text-align: center;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
      -webkit-overflow-scrolling: touch
  }

      .nav--header li {
          margin: 24px 0
      }

          .nav--header li.item-logo {
              position: fixed;
              top: 0
          }

      .nav--header ul {
          position: absolute;
          top: 50%;
          left: 0;
          overflow: auto;
          width: 100%;
          max-height: 100%;
          margin: 0;
          padding: 0;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          -webkit-touch-callout: none;
          -webkit-overflow-scrolling: touch
      }

          .nav--header ul > li {
              width: 100%;
              margin: 0
          }
}

@media screen and (max-width: 1024px) {
  .nav--footer {
      text-align: left;
      margin-top: 24px
  }

      .nav--footer li {
          display: block
      }
}

.alert {
  padding: 24px;
  margin-bottom: 24px;
  border: 1px solid transparent
}

  .alert > p, .alert > ul {
      margin-bottom: 0
  }

      .alert > p + p {
          margin-top: 24px
      }

  .alert.alert--success {
      color: #5CB85C;
      border-color: #5CB85C
  }

  .alert.alert--info {
      color: #F2B32B;
      border-color: #F2B32B
  }

  .alert.alert--warning {
      color: #F0AD4E;
      border-color: #F0AD4E
  }

  .alert.alert--danger {
      color: #D9534F;
      border-color: #D9534F
  }

.modal {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 560px;
  max-width: calc(100% - 48px);
  padding: 24px;
  color: #fff;
  border: 1px #fff solid;
  background-color: #BF6046;
  z-index: 5
}

  .modal .modal__close {
      position: absolute;
      top: 12px;
      right: 12px;
      cursor: pointer
  }

      .modal .modal__close svg {
          width: 25px;
          height: 25px
      }

  .modal p:last-of-type {
      margin-bottom: 0
  }

  .modal a {
      color: #273240
  }


/*button*/

.mat-button .mat-button-focus-overlay, .mat-icon-button .mat-button-focus-overlay {
  opacity: 0
}

.mat-button:hover:not(.mat-button-disabled) .mat-button-focus-overlay, .mat-stroked-button:hover:not(.mat-button-disabled) .mat-button-focus-overlay {
  opacity: .04
}

@media(hover: none) {
  .mat-button:hover:not(.mat-button-disabled) .mat-button-focus-overlay, .mat-stroked-button:hover:not(.mat-button-disabled) .mat-button-focus-overlay {
      opacity: 0
  }
}

.mat-button, .mat-icon-button, .mat-stroked-button, .mat-flat-button {
  box-sizing: border-box;
  position: relative;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  outline: none;
  border: none;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 64px;
  line-height: 36px;
  padding: 0 16px;
  border-radius: 4px;
  overflow: visible
}

  .mat-button::-moz-focus-inner, .mat-icon-button::-moz-focus-inner, .mat-stroked-button::-moz-focus-inner, .mat-flat-button::-moz-focus-inner {
      border: 0
  }

  .mat-button.mat-button-disabled, .mat-icon-button.mat-button-disabled, .mat-stroked-button.mat-button-disabled, .mat-flat-button.mat-button-disabled {
      cursor: default
  }

  .mat-button.cdk-keyboard-focused .mat-button-focus-overlay, .mat-button.cdk-program-focused .mat-button-focus-overlay, .mat-icon-button.cdk-keyboard-focused .mat-button-focus-overlay, .mat-icon-button.cdk-program-focused .mat-button-focus-overlay, .mat-stroked-button.cdk-keyboard-focused .mat-button-focus-overlay, .mat-stroked-button.cdk-program-focused .mat-button-focus-overlay, .mat-flat-button.cdk-keyboard-focused .mat-button-focus-overlay, .mat-flat-button.cdk-program-focused .mat-button-focus-overlay {
      opacity: .12
  }

  .mat-button::-moz-focus-inner, .mat-icon-button::-moz-focus-inner, .mat-stroked-button::-moz-focus-inner, .mat-flat-button::-moz-focus-inner {
      border: 0
  }

.mat-raised-button {
  box-sizing: border-box;
  position: relative;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  outline: none;
  border: none;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 64px;
  line-height: 36px;
  padding: 0 16px;
  border-radius: 4px;
  overflow: visible;
  transform: translate3d(0, 0, 0);
  transition: background 400ms cubic-bezier(0.25, 0.8, 0.25, 1),box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1)
}

  .mat-raised-button::-moz-focus-inner {
      border: 0
  }

  .mat-raised-button.mat-button-disabled {
      cursor: default
  }

  .mat-raised-button.cdk-keyboard-focused .mat-button-focus-overlay, .mat-raised-button.cdk-program-focused .mat-button-focus-overlay {
      opacity: .12
  }

  .mat-raised-button::-moz-focus-inner {
      border: 0
  }

._mat-animation-noopable.mat-raised-button {
  transition: none;
  animation: none
}

.mat-stroked-button {
  border: 1px solid currentColor;
  padding: 0 15px;
  line-height: 34px
}

  .mat-stroked-button .mat-button-ripple.mat-ripple, .mat-stroked-button .mat-button-focus-overlay {
      top: -1px;
      left: -1px;
      right: -1px;
      bottom: -1px
  }

.mat-fab {
  box-sizing: border-box;
  position: relative;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  outline: none;
  border: none;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 64px;
  line-height: 36px;
  padding: 0 16px;
  border-radius: 4px;
  overflow: visible;
  transform: translate3d(0, 0, 0);
  transition: background 400ms cubic-bezier(0.25, 0.8, 0.25, 1),box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  min-width: 0;
  border-radius: 50%;
  width: 56px;
  height: 56px;
  padding: 0;
  flex-shrink: 0
}

  .mat-fab::-moz-focus-inner {
      border: 0
  }

  .mat-fab.mat-button-disabled {
      cursor: default
  }

  .mat-fab.cdk-keyboard-focused .mat-button-focus-overlay, .mat-fab.cdk-program-focused .mat-button-focus-overlay {
      opacity: .12
  }

  .mat-fab::-moz-focus-inner {
      border: 0
  }

._mat-animation-noopable.mat-fab {
  transition: none;
  animation: none
}

.mat-fab .mat-button-wrapper {
  padding: 16px 0;
  display: inline-block;
  line-height: 24px
}

.mat-mini-fab {
  box-sizing: border-box;
  position: relative;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  outline: none;
  border: none;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 64px;
  line-height: 36px;
  padding: 0 16px;
  border-radius: 4px;
  overflow: visible;
  transform: translate3d(0, 0, 0);
  transition: background 400ms cubic-bezier(0.25, 0.8, 0.25, 1),box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  min-width: 0;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 0;
  flex-shrink: 0
}

  .mat-mini-fab::-moz-focus-inner {
      border: 0
  }

  .mat-mini-fab.mat-button-disabled {
      cursor: default
  }

  .mat-mini-fab.cdk-keyboard-focused .mat-button-focus-overlay, .mat-mini-fab.cdk-program-focused .mat-button-focus-overlay {
      opacity: .12
  }

  .mat-mini-fab::-moz-focus-inner {
      border: 0
  }

._mat-animation-noopable.mat-mini-fab {
  transition: none;
  animation: none
}

.mat-mini-fab .mat-button-wrapper {
  padding: 8px 0;
  display: inline-block;
  line-height: 24px
}

.mat-icon-button {
  padding: 0;
  min-width: 0;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  line-height: 40px;
  border-radius: 50%
}

  .mat-icon-button i, .mat-icon-button .mat-icon {
      line-height: 24px
  }

.mat-button-ripple.mat-ripple, .mat-button-focus-overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  pointer-events: none;
  border-radius: inherit
}

  .mat-button-ripple.mat-ripple:not(:empty) {
      transform: translateZ(0)
  }

.mat-button-focus-overlay {
  opacity: 0;
  transition: opacity 200ms cubic-bezier(0.35, 0, 0.25, 1),background-color 200ms cubic-bezier(0.35, 0, 0.25, 1)
}

._mat-animation-noopable .mat-button-focus-overlay {
  transition: none
}

.mat-button-ripple-round {
  border-radius: 50%;
  z-index: 1
}

.mat-button .mat-button-wrapper > *, .mat-flat-button .mat-button-wrapper > *, .mat-stroked-button .mat-button-wrapper > *, .mat-raised-button .mat-button-wrapper > *, .mat-icon-button .mat-button-wrapper > *, .mat-fab .mat-button-wrapper > *, .mat-mini-fab .mat-button-wrapper > * {
  vertical-align: middle
}

.mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-prefix .mat-icon-button, .mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix .mat-icon-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: inherit;
  width: 2.5em;
  height: 2.5em
}

.cdk-high-contrast-active .mat-button, .cdk-high-contrast-active .mat-flat-button, .cdk-high-contrast-active .mat-raised-button, .cdk-high-contrast-active .mat-icon-button, .cdk-high-contrast-active .mat-fab, .cdk-high-contrast-active .mat-mini-fab {
  outline: solid 1px
}

.cdk-high-contrast-active .mat-button-base.cdk-keyboard-focused, .cdk-high-contrast-active .mat-button-base.cdk-program-focused {
  outline: solid 3px
}



.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px
}

.mat-badge-large .mat-badge-content {
  font-size: 24px
}

.mat-h1, .mat-headline, .mat-typography .mat-h1, .mat-typography .mat-headline, .mat-typography h1 {
  font: 400 24px/32px;
  letter-spacing: normal;
  margin: 0 0 16px
}

.mat-h2, .mat-title, .mat-typography .mat-h2, .mat-typography .mat-title, .mat-typography h2 {
  font: 500 20px/32px;
  letter-spacing: normal;
  margin: 0 0 16px
}

.mat-h3, .mat-subheading-2, .mat-typography .mat-h3, .mat-typography .mat-subheading-2, .mat-typography h3 {
  font: 400 16px/28px;
  letter-spacing: normal;
  margin: 0 0 16px
}

.mat-h4, .mat-subheading-1, .mat-typography .mat-h4, .mat-typography .mat-subheading-1, .mat-typography h4 {
  font: 400 15px/24px;
  letter-spacing: normal;
  margin: 0 0 16px
}

.mat-h5, .mat-typography .mat-h5, .mat-typography h5 {
  font: 400 11.62px/20px;
  margin: 0 0 12px
}

.mat-h6, .mat-typography .mat-h6, .mat-typography h6 {
  font: 400 9.38px/20px;
  margin: 0 0 12px
}

.mat-body-strong, .mat-body-2, .mat-typography .mat-body-strong, .mat-typography .mat-body-2 {
  font: 500 14px/24px;
  letter-spacing: normal
}

.mat-body, .mat-body-1, .mat-typography .mat-body, .mat-typography .mat-body-1, .mat-typography {
  font: 400 14px/20px;
  letter-spacing: normal
}

  .mat-body p, .mat-body-1 p, .mat-typography .mat-body p, .mat-typography .mat-body-1 p, .mat-typography p {
      margin: 0 0 12px
  }

  .mat-small, .mat-caption, .mat-typography .mat-small, .mat-typography .mat-caption {
      font: 400 12px/20px;
      letter-spacing: normal
  }

  .mat-display-4, .mat-typography .mat-display-4 {
      font: 300 112px/112px;
      letter-spacing: -.05em;
      margin: 0 0 56px
  }

  .mat-display-3, .mat-typography .mat-display-3 {
      font: 400 56px/56px;
      letter-spacing: -.02em;
      margin: 0 0 64px
  }

  .mat-display-2, .mat-typography .mat-display-2 {
      font: 400 45px/48px;
      letter-spacing: -.005em;
      margin: 0 0 64px
  }

  .mat-display-1, .mat-typography .mat-display-1 {
      font: 400 34px/40px;
      letter-spacing: normal;
      margin: 0 0 64px
  }

.mat-bottom-sheet-container {
  font: 400 14px/20px;
  letter-spacing: normal
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button, .mat-flat-button, .mat-fab, .mat-mini-fab {
  font-size: 14px;
  font-weight: 500
}

.mat-card-title {
  font-size: 24px;
  font-weight: 500
}

.mat-card-header .mat-card-title {
  font-size: 20px
}

.mat-card-subtitle, .mat-card-content {
  font-size: 14px
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px
}

.mat-chip {
  font-size: 14px;
  font-weight: 500
}

.mat-chip .mat-chip-trailing-icon.mat-icon, .mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500
}

.mat-cell, .mat-footer-cell {
  font-size: 14px
}

.mat-calendar-body {
  font-size: 13px
}

.mat-calendar-body-label, .mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400
}

.mat-dialog-title {
  font: 500 20px/32px Roboto,Helvetica Neue,sans-serif;
  letter-spacing: normal
}

.mat-expansion-panel-header {
  font-size: 15px;
  font-weight: 400
}

.mat-expansion-panel-content {
  font: 400 14px/20px Roboto,Helvetica Neue,sans-serif;
  letter-spacing: normal
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  letter-spacing: normal
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em
}

.mat-form-field-prefix .mat-icon, .mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125
}

.mat-form-field-prefix .mat-icon-button, .mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em
}

  .mat-form-field-prefix .mat-icon-button .mat-icon, .mat-form-field-suffix .mat-icon-button .mat-icon {
      height: 1.125em;
      line-height: 1.125
  }

.mat-form-field-infix {
  padding: .5em 0;
  border-top: .84375em solid transparent
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label, .mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(.75);
  width: 133.3333333333%
}

.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(.75);
  width: 133.3333433333%
}

.mat-form-field-label-wrapper {
  top: -.84375em;
  padding-top: .84375em
}

.mat-form-field-label {
  top: 1.34375em
}

.mat-form-field-underline {
  bottom: 1.34375em
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: .6666666667em;
  top: calc(100% - 1.7916666667em)
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em
}

.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: .4375em 0
}

.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label, .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(.75) perspective(100px) translateZ(.001px);
  width: 133.3333333333%
}

.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(.75) perspective(100px) translateZ(.00101px);
  width: 133.3333433333%
}

.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(.75) perspective(100px) translateZ(.00102px);
  width: 133.3333533333%
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em
}

.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: .5416666667em;
  top: calc(100% - 1.6666666667em)
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label, .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
      transform: translateY(-1.28122em) scale(.75)
  }

  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
      transform: translateY(-1.28121em) scale(.75)
  }

  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
      transform: translateY(-1.2812em) scale(.75)
  }
}

.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: .25em 0 .75em
}

.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -.5em
}

.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label, .mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-.59375em) scale(.75);
  width: 133.3333333333%
}

.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-.59374em) scale(.75);
  width: 133.3333433333%
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0
}

.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -.25em
}

.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label, .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(.75);
  width: 133.3333333333%
}

.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(.75);
  width: 133.3333433333%
}

.mat-grid-tile-header, .mat-grid-tile-footer {
  font-size: 14px
}

  .mat-grid-tile-header .mat-line, .mat-grid-tile-footer .mat-line {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      box-sizing: border-box
  }

      .mat-grid-tile-header .mat-line:nth-child(n+2), .mat-grid-tile-footer .mat-line:nth-child(n+2) {
          font-size: 12px
      }

input.mat-input-element {
  margin-top: -.0625em
}

.mat-menu-item {
  font-size: 14px;
  font-weight: 400
}

.mat-paginator, .mat-paginator-page-size .mat-select-trigger {
  font-size: 12px
}

.mat-select-trigger {
  height: 1.125em
}

.mat-slider-thumb-label-text {
  font-size: 12px;
  font-weight: 500
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400
}

.mat-step-sub-label-error {
  font-weight: 400
}

.mat-step-label-error {
  font-size: 14px
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500
}

.mat-tab-label, .mat-tab-link {
  font-size: 14px;
  font-weight: 500
}

.mat-toolbar, .mat-toolbar h1, .mat-toolbar h2, .mat-toolbar h3, .mat-toolbar h4, .mat-toolbar h5, .mat-toolbar h6 {
  font: 500 20px/32px Roboto,Helvetica Neue,sans-serif;
  letter-spacing: normal;
  margin: 0
}

.mat-tooltip {
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px
}

.mat-list-base .mat-list-item {
  font-size: 16px
}

  .mat-list-base .mat-list-item .mat-line {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      box-sizing: border-box
  }

      .mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
          font-size: 14px
      }

.mat-list-base .mat-list-option {
  font-size: 16px
}

  .mat-list-base .mat-list-option .mat-line {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      box-sizing: border-box
  }

      .mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
          font-size: 14px
      }

.mat-list-base .mat-subheader {
  font-size: 14px;
  font-weight: 500
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px
}

  .mat-list-base[dense] .mat-list-item .mat-line {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      box-sizing: border-box
  }

      .mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
          font-size: 12px
      }

.mat-list-base[dense] .mat-list-option {
  font-size: 12px
}

  .mat-list-base[dense] .mat-list-option .mat-line {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      box-sizing: border-box
  }

      .mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
          font-size: 12px
      }

.mat-list-base[dense] .mat-subheader {
  font-size: 12px;
  font-weight: 500
}

.mat-option {
  font-size: 16px
}

.mat-optgroup-label {
  font: 500 14px/24px Roboto,Helvetica Neue,sans-serif;
  letter-spacing: normal
}

.mat-simple-snackbar {
  font-size: 14px
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500
}

.mat-tree-node, .mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px
}

.mat-ripple {
  overflow: hidden;
  position: relative
}

  .mat-ripple:not(:empty) {
      transform: translateZ(0)
  }

  .mat-ripple.mat-ripple-unbounded {
      overflow: visible
  }

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity,transform 0ms cubic-bezier(0,0,.2,1);
  transform: scale(0)
}

.cdk-high-contrast-active .mat-ripple-element {
  display: none
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0
}

[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000
}

  .cdk-overlay-container:empty {
      display: none
  }

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%
}

.cdk-overlay-backdrop {
  position: absolute;
  inset: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity .4s cubic-bezier(.25,.8,.25,1);
  opacity: 0
}

  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
      opacity: 1
  }

.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: .6
}

.cdk-overlay-dark-backdrop {
  background: rgba(0,0,0,.32)
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear,opacity 1ms linear;
  visibility: hidden;
  opacity: 1
}

  .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
      opacity: 0;
      visibility: visible
  }

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll
}

textarea.cdk-textarea-autosize {
  resize: none
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important
}

@keyframes cdk-text-field-autofill-start {
}

@keyframes cdk-text-field-autofill-end {
}

.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms
}

.mat-focus-indicator, .mat-mdc-focus-indicator {
  position: relative
}

.mat-ripple-element {
  background-color: #0000001a
}

.mat-option {
  color: #000000de
}

  .mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
      background: rgba(0,0,0,.04)
  }

  .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
      background: rgba(0,0,0,.04)
  }

  .mat-option.mat-active {
      background: rgba(0,0,0,.04);
      color: #000000de
  }

  .mat-option.mat-option-disabled {
      color: #00000061
  }

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f15b2b
}

.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ff4081
}

.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f44336
}

.mat-optgroup-label {
  color: #0000008a
}

.mat-optgroup-disabled .mat-optgroup-label {
  color: #00000061
}

.mat-pseudo-checkbox {
  color: #0000008a
}

  .mat-pseudo-checkbox:after {
      color: #fafafa
  }

.mat-pseudo-checkbox-disabled {
  color: #b0b0b0
}

.mat-primary .mat-pseudo-checkbox-checked, .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #f15b2b
}

.mat-pseudo-checkbox-checked, .mat-pseudo-checkbox-indeterminate, .mat-accent .mat-pseudo-checkbox-checked, .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #ff4081
}

.mat-warn .mat-pseudo-checkbox-checked, .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #f44336
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled, .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0
}

.mat-app-background {
  background-color: #fafafa;
  color: #000000de
}

.mat-elevation-z0 {
  box-shadow: 0 0 #0003,0 0 #00000024,0 0 #0000001f
}

.mat-elevation-z1 {
  box-shadow: 0 2px 1px -1px #0003,0 1px 1px #00000024,0 1px 3px #0000001f
}

.mat-elevation-z2 {
  box-shadow: 0 3px 1px -2px #0003,0 2px 2px #00000024,0 1px 5px #0000001f
}

.mat-elevation-z3 {
  box-shadow: 0 3px 3px -2px #0003,0 3px 4px #00000024,0 1px 8px #0000001f
}

.mat-elevation-z4 {
  box-shadow: 0 2px 4px -1px #0003,0 4px 5px #00000024,0 1px 10px #0000001f
}

.mat-elevation-z5 {
  box-shadow: 0 3px 5px -1px #0003,0 5px 8px #00000024,0 1px 14px #0000001f
}

.mat-elevation-z6 {
  box-shadow: 0 3px 5px -1px #0003,0 6px 10px #00000024,0 1px 18px #0000001f
}

.mat-elevation-z7 {
  box-shadow: 0 4px 5px -2px #0003,0 7px 10px 1px #00000024,0 2px 16px 1px #0000001f
}

.mat-elevation-z8 {
  box-shadow: 0 5px 5px -3px #0003,0 8px 10px 1px #00000024,0 3px 14px 2px #0000001f
}

.mat-elevation-z9 {
  box-shadow: 0 5px 6px -3px #0003,0 9px 12px 1px #00000024,0 3px 16px 2px #0000001f
}

.mat-elevation-z10 {
  box-shadow: 0 6px 6px -3px #0003,0 10px 14px 1px #00000024,0 4px 18px 3px #0000001f
}

.mat-elevation-z11 {
  box-shadow: 0 6px 7px -4px #0003,0 11px 15px 1px #00000024,0 4px 20px 3px #0000001f
}

.mat-elevation-z12 {
  box-shadow: 0 7px 8px -4px #0003,0 12px 17px 2px #00000024,0 5px 22px 4px #0000001f
}

.mat-elevation-z13 {
  box-shadow: 0 7px 8px -4px #0003,0 13px 19px 2px #00000024,0 5px 24px 4px #0000001f
}

.mat-elevation-z14 {
  box-shadow: 0 7px 9px -4px #0003,0 14px 21px 2px #00000024,0 5px 26px 4px #0000001f
}

.mat-elevation-z15 {
  box-shadow: 0 8px 9px -5px #0003,0 15px 22px 2px #00000024,0 6px 28px 5px #0000001f
}

.mat-elevation-z16 {
  box-shadow: 0 8px 10px -5px #0003,0 16px 24px 2px #00000024,0 6px 30px 5px #0000001f
}

.mat-elevation-z17 {
  box-shadow: 0 8px 11px -5px #0003,0 17px 26px 2px #00000024,0 6px 32px 5px #0000001f
}

.mat-elevation-z18 {
  box-shadow: 0 9px 11px -5px #0003,0 18px 28px 2px #00000024,0 7px 34px 6px #0000001f
}

.mat-elevation-z19 {
  box-shadow: 0 9px 12px -6px #0003,0 19px 29px 2px #00000024,0 7px 36px 6px #0000001f
}

.mat-elevation-z20 {
  box-shadow: 0 10px 13px -6px #0003,0 20px 31px 3px #00000024,0 8px 38px 7px #0000001f
}

.mat-elevation-z21 {
  box-shadow: 0 10px 13px -6px #0003,0 21px 33px 3px #00000024,0 8px 40px 7px #0000001f
}

.mat-elevation-z22 {
  box-shadow: 0 10px 14px -6px #0003,0 22px 35px 3px #00000024,0 8px 42px 7px #0000001f
}

.mat-elevation-z23 {
  box-shadow: 0 11px 14px -7px #0003,0 23px 36px 3px #00000024,0 9px 44px 8px #0000001f
}

.mat-elevation-z24 {
  box-shadow: 0 11px 15px -7px #0003,0 24px 38px 3px #00000024,0 9px 46px 8px #0000001f
}

.mat-theme-loaded-marker {
  display: none
}

.mat-autocomplete-panel {
  background: #fff;
  color: #000000de
}

  .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
      box-shadow: 0 2px 4px -1px #0003,0 4px 5px #00000024,0 1px 10px #0000001f
  }

  .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
      background: #fff
  }

      .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
          color: #000000de
      }

.mat-badge {
  position: relative
}

  .mat-badge.mat-badge {
      overflow: visible
  }

.mat-badge-hidden .mat-badge-content {
  display: none
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform .2s ease-in-out;
  transform: scale(.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none
}

  .ng-animate-disabled .mat-badge-content, .mat-badge-content._mat-animation-noopable {
      transition: none
  }

  .mat-badge-content.mat-badge-active {
      transform: none
  }

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px
}

.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px
}

.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px
}

.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px
}

[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px
}

.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px
}

[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px
}

.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px
}

[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px
}

.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px
}

[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px
}

.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px
}

.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px
}

.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px
}

[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px
}

.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px
}

[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px
}

.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px
}

[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px
}

.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px
}

[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px
}

.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px
}

.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px
}

.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px
}

[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px
}

.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px
}

[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px
}

.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px
}

[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px
}

.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px
}

[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px
}

.mat-badge-content {
  color: #fff;
  background: #f15b2b
}

.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0
}

.mat-badge-accent .mat-badge-content {
  background: #ff4081;
  color: #fff
}

.mat-badge-warn .mat-badge-content {
  color: #fff;
  background: #f44336
}

.mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: #00000061
}

.mat-bottom-sheet-container {
  box-shadow: 0 8px 10px -5px #0003,0 16px 24px 2px #00000024,0 6px 30px 5px #0000001f;
  background: #fff;
  color: #000000de
}

.mat-button, .mat-icon-button, .mat-stroked-button {
  color: inherit;
  background: transparent
}

  .mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
      color: #3f51b5
  }

  .mat-button.mat-accent, .mat-icon-button.mat-accent, .mat-stroked-button.mat-accent {
      color: #ff4081
  }

  .mat-button.mat-warn, .mat-icon-button.mat-warn, .mat-stroked-button.mat-warn {
      color: #f44336
  }

      .mat-button.mat-primary.mat-button-disabled, .mat-button.mat-accent.mat-button-disabled, .mat-button.mat-warn.mat-button-disabled, .mat-button.mat-button-disabled.mat-button-disabled, .mat-icon-button.mat-primary.mat-button-disabled, .mat-icon-button.mat-accent.mat-button-disabled, .mat-icon-button.mat-warn.mat-button-disabled, .mat-icon-button.mat-button-disabled.mat-button-disabled, .mat-stroked-button.mat-primary.mat-button-disabled, .mat-stroked-button.mat-accent.mat-button-disabled, .mat-stroked-button.mat-warn.mat-button-disabled, .mat-stroked-button.mat-button-disabled.mat-button-disabled {
          color: #00000042
      }

  .mat-button.mat-primary .mat-button-focus-overlay, .mat-icon-button.mat-primary .mat-button-focus-overlay, .mat-stroked-button.mat-primary .mat-button-focus-overlay {
      background-color: #3f51b5
  }

  .mat-button.mat-accent .mat-button-focus-overlay, .mat-icon-button.mat-accent .mat-button-focus-overlay, .mat-stroked-button.mat-accent .mat-button-focus-overlay {
      background-color: #ff4081
  }

  .mat-button.mat-warn .mat-button-focus-overlay, .mat-icon-button.mat-warn .mat-button-focus-overlay, .mat-stroked-button.mat-warn .mat-button-focus-overlay {
      background-color: #f44336
  }

  .mat-button.mat-button-disabled .mat-button-focus-overlay, .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
      background-color: transparent
  }

  .mat-button .mat-ripple-element, .mat-icon-button .mat-ripple-element, .mat-stroked-button .mat-ripple-element {
      opacity: .1;
      background-color: currentColor
  }

.mat-button-focus-overlay {
  background: #000
}

.mat-stroked-button:not(.mat-button-disabled) {
  border-color: #0000001f
}

.mat-flat-button, .mat-raised-button, .mat-fab, .mat-mini-fab {
  color: #000000de;
  background-color: #fff
}

  .mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary, .mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent, .mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
      color: #fff
  }

      .mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
          color: #00000042
      }

  .mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
      background-color: #f15b2b
  }

  .mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
      background-color: #ff4081
  }

  .mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
      background-color: #f44336
  }

      .mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
          background-color: #0000001f
      }

      .mat-flat-button.mat-primary .mat-ripple-element, .mat-raised-button.mat-primary .mat-ripple-element, .mat-fab.mat-primary .mat-ripple-element, .mat-mini-fab.mat-primary .mat-ripple-element, .mat-flat-button.mat-accent .mat-ripple-element, .mat-raised-button.mat-accent .mat-ripple-element, .mat-fab.mat-accent .mat-ripple-element, .mat-mini-fab.mat-accent .mat-ripple-element, .mat-flat-button.mat-warn .mat-ripple-element, .mat-raised-button.mat-warn .mat-ripple-element, .mat-fab.mat-warn .mat-ripple-element, .mat-mini-fab.mat-warn .mat-ripple-element {
          background-color: #ffffff1a
      }

  .mat-stroked-button:not([class*=mat-elevation-z]), .mat-flat-button:not([class*=mat-elevation-z]) {
      box-shadow: 0 0 #0003,0 0 #00000024,0 0 #0000001f
  }

  .mat-raised-button:not([class*=mat-elevation-z]) {
      box-shadow: 0 3px 1px -2px #0003,0 2px 2px #00000024,0 1px 5px #0000001f
  }

  .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
      box-shadow: 0 5px 5px -3px #0003,0 8px 10px 1px #00000024,0 3px 14px 2px #0000001f
  }

  .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
      box-shadow: 0 0 #0003,0 0 #00000024,0 0 #0000001f
  }

  .mat-fab:not([class*=mat-elevation-z]), .mat-mini-fab:not([class*=mat-elevation-z]) {
      box-shadow: 0 3px 5px -1px #0003,0 6px 10px #00000024,0 1px 18px #0000001f
  }

  .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
      box-shadow: 0 7px 8px -4px #0003,0 12px 17px 2px #00000024,0 5px 22px 4px #0000001f
  }

  .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
      box-shadow: 0 0 #0003,0 0 #00000024,0 0 #0000001f
  }

.mat-button-toggle-standalone:not([class*=mat-elevation-z]), .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0 3px 1px -2px #0003,0 2px 2px #00000024,0 1px 5px #0000001f
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]), .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none
}

.mat-button-toggle {
  color: #00000061
}

  .mat-button-toggle .mat-button-toggle-focus-overlay {
      background-color: #0000001f
  }

.mat-button-toggle-appearance-standard {
  color: #000000de;
  background: #fff
}

  .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
      background-color: #000
  }

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0
}

[dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0
}

.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0
}

.mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: #0000008a
}

  .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
      color: #000000de
  }

.mat-button-toggle-disabled {
  color: #00000042;
  background-color: #eee
}

  .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
      background: #fff
  }

  .mat-button-toggle-disabled.mat-button-toggle-checked {
      background-color: #bdbdbd
  }

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard, .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px
}

.mat-card {
  background: #fff;
  color: #000000de
}

  .mat-card:not([class*=mat-elevation-z]) {
      box-shadow: 0 2px 1px -1px #0003,0 1px 1px #00000024,0 1px 3px #0000001f
  }

  .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
      box-shadow: 0 0 #0003,0 0 #00000024,0 0 #0000001f
  }

.mat-card-subtitle {
  color: #0000008a
}

.mat-checkbox-frame {
  border-color: #0000008a
}

.mat-checkbox-checkmark {
  fill: #fafafa
}

.mat-checkbox-checkmark-path {
  stroke: #fafafa !important
}

.mat-checkbox-mixedmark {
  background-color: #fafafa
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #3f51b5
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #ff4081
}

.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #f44336
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0
}

.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0
}

.mat-checkbox-disabled .mat-checkbox-label {
  color: #00000061
}

.mat-checkbox .mat-ripple-element {
  background-color: #000
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element, .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #3f51b5
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element, .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #ff4081
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element, .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #f44336
}

.mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: #000000de
}

  .mat-chip.mat-standard-chip .mat-chip-remove {
      color: #000000de;
      opacity: .4
  }

  .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
      box-shadow: 0 3px 3px -2px #0003,0 3px 4px #00000024,0 1px 8px #0000001f
  }

  .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
      opacity: .54
  }

  .mat-chip.mat-standard-chip.mat-chip-disabled {
      opacity: .4
  }

  .mat-chip.mat-standard-chip:after {
      background: #000
  }

  .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
      background-color: #3f51b5;
      color: #fff
  }

      .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
          color: #fff;
          opacity: .4
      }

      .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
          background-color: #ffffff1a
      }

  .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
      background-color: #f44336;
      color: #fff
  }

      .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
          color: #fff;
          opacity: .4
      }

      .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
          background-color: #ffffff1a
      }

  .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
      background-color: #ff4081;
      color: #fff
  }

      .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
          color: #fff;
          opacity: .4
      }

      .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
          background-color: #ffffff1a
      }

.mat-table {
  background: #fff
}

  .mat-table thead, .mat-table tbody, .mat-table tfoot, mat-header-row, mat-row, mat-footer-row, [mat-header-row], [mat-row], [mat-footer-row], .mat-table-sticky {
      background: inherit
  }

mat-row, mat-header-row, mat-footer-row, th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  border-bottom-color: #0000001f
}

.mat-header-cell {
  color: #0000008a
}

.mat-cell, .mat-footer-cell {
  color: #000000de
}

.mat-calendar-arrow {
  fill: #0000008a
}

.mat-datepicker-toggle, .mat-datepicker-content .mat-calendar-next-button, .mat-datepicker-content .mat-calendar-previous-button {
  color: #0000008a
}

.mat-calendar-table-header-divider:after {
  background: rgba(0,0,0,.12)
}

.mat-calendar-table-header, .mat-calendar-body-label {
  color: #0000008a
}

.mat-calendar-body-cell-content, .mat-date-range-input-separator {
  color: #000000de;
  border-color: transparent
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: #00000061
}

.mat-form-field-disabled .mat-date-range-input-separator {
  color: #00000061
}

.mat-calendar-body-in-preview {
  color: #0000003d
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: #00000061
}

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: #0000002e
}

.mat-calendar-body-in-range:before {
  background: rgba(63,81,181,.2)
}

.mat-calendar-body-comparison-identical, .mat-calendar-body-in-comparison-range:before {
  background: rgba(249,171,0,.2)
}

.mat-calendar-body-comparison-bridge-start:before, [dir=rtl] .mat-calendar-body-comparison-bridge-end:before {
  background: linear-gradient(to right,rgba(63,81,181,.2) 50%,rgba(249,171,0,.2) 50%)
}

.mat-calendar-body-comparison-bridge-end:before, [dir=rtl] .mat-calendar-body-comparison-bridge-start:before {
  background: linear-gradient(to left,rgba(63,81,181,.2) 50%,rgba(249,171,0,.2) 50%)
}

.mat-calendar-body-in-range > .mat-calendar-body-comparison-identical, .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range:after {
  background: #a8dab5
}

.mat-calendar-body-comparison-identical.mat-calendar-body-selected, .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e
}

.mat-calendar-body-selected {
  background-color: #3f51b5;
  color: #fff
}

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: #3f51b566
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px #fff
}

.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical), .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: #3f51b54d
}

@media (hover: hover) {
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
      background-color: #3f51b54d
  }
}

.mat-datepicker-content {
  box-shadow: 0 2px 4px -1px #0003,0 4px 5px #00000024,0 1px 10px #0000001f;
  background-color: #fff;
  color: #000000de
}

  .mat-datepicker-content.mat-accent .mat-calendar-body-in-range:before {
      background: rgba(255,64,129,.2)
  }

  .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical, .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range:before {
      background: rgba(249,171,0,.2)
  }

  .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start:before, .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end:before {
      background: linear-gradient(to right,rgba(255,64,129,.2) 50%,rgba(249,171,0,.2) 50%)
  }

  .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end:before, .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start:before {
      background: linear-gradient(to left,rgba(255,64,129,.2) 50%,rgba(249,171,0,.2) 50%)
  }

  .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical, .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range:after {
      background: #a8dab5
  }

  .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected, .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
      background: #46a35e
  }

  .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
      background-color: #ff4081;
      color: #fff
  }

  .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
      background-color: #ff408166
  }

  .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
      box-shadow: inset 0 0 0 1px #fff
  }

  .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical), .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
      background-color: #ff40814d
  }

@media (hover: hover) {
  .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
      background-color: #ff40814d
  }
}

.mat-datepicker-content.mat-warn .mat-calendar-body-in-range:before {
  background: rgba(244,67,54,.2)
}

.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical, .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range:before {
  background: rgba(249,171,0,.2)
}

.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start:before, .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end:before {
  background: linear-gradient(to right,rgba(244,67,54,.2) 50%,rgba(249,171,0,.2) 50%)
}

.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end:before, .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start:before {
  background: linear-gradient(to left,rgba(244,67,54,.2) 50%,rgba(249,171,0,.2) 50%)
}

.mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical, .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range:after {
  background: #a8dab5
}

.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected, .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e
}

.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: #fff
}

.mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: #f4433666
}

.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px #fff
}

.mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical), .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: #f443364d
}

@media (hover: hover) {
  .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
      background-color: #f443364d
  }
}

.mat-datepicker-content-touch {
  box-shadow: 0 11px 15px -7px #0003,0 24px 38px 3px #00000024,0 9px 46px 8px #0000001f
}

.mat-datepicker-toggle-active {
  color: #3f51b5
}

  .mat-datepicker-toggle-active.mat-accent {
      color: #ff4081
  }

  .mat-datepicker-toggle-active.mat-warn {
      color: #f44336
  }

.mat-date-range-input-inner[disabled] {
  color: #00000061
}

.mat-dialog-container {
  box-shadow: 0 11px 15px -7px #0003,0 24px 38px 3px #00000024,0 9px 46px 8px #0000001f;
  background: #fff;
  color: #000000de
}

.mat-divider {
  border-top-color: #0000001f
}

.mat-divider-vertical {
  border-right-color: #0000001f
}

.mat-expansion-panel {
  background: #fff;
  color: #000000de
}

  .mat-expansion-panel:not([class*=mat-elevation-z]) {
      box-shadow: 0 3px 1px -2px #0003,0 2px 2px #00000024,0 1px 5px #0000001f
  }

.mat-action-row {
  border-top-color: #0000001f
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0,0,0,.04)
}

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
      background: #fff
  }
}

.mat-expansion-panel-header-title {
  color: #000000de
}

.mat-expansion-panel-header-description, .mat-expansion-indicator:after {
  color: #0000008a
}

.mat-expansion-panel-header[aria-disabled=true] {
  color: #00000042
}

  .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title, .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
      color: inherit
  }

.mat-expansion-panel-header {
  height: 48px
}

  .mat-expansion-panel-header.mat-expanded {
      height: 64px
  }

.mat-form-field-label, .mat-hint {
  color: #0009
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #3f51b5
}

  .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
      color: #ff4081
  }

  .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
      color: #f44336
  }

.mat-focused .mat-form-field-required-marker {
  color: #ff4081
}

.mat-form-field-ripple {
  background-color: #000000de
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #3f51b5
}

  .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
      background-color: #ff4081
  }

  .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
      background-color: #f44336
  }

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix:after {
  color: #3f51b5
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix:after {
  color: #ff4081
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix:after {
  color: #f44336
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label, .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent, .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #f44336
}

.mat-form-field.mat-form-field-invalid .mat-form-field-ripple, .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #f44336
}

.mat-error {
  color: #f44336
}

.mat-form-field-appearance-legacy .mat-form-field-label, .mat-form-field-appearance-legacy .mat-hint {
  color: #0000008a
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: #0000006b
}

.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right,rgba(0,0,0,.42) 0%,rgba(0,0,0,.42) 33%,transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x
}

.mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: #0000006b
}

.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right,rgba(0,0,0,.42) 0%,rgba(0,0,0,.42) 33%,transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: #0000000a
}

.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: #00000005
}

.mat-form-field-appearance-fill .mat-form-field-underline:before {
  background-color: #0000006b
}

.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: #00000061
}

.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline:before {
  background-color: transparent
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: #0000001f
}

.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: #000000de
}

.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #3f51b5
}

.mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #ff4081
}

.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick, .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #f44336
}

.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: #00000061
}

.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: #0000000f
}

.mat-icon.mat-primary {
  color: #3f51b5
}

.mat-icon.mat-accent {
  color: #ff4081
}

.mat-icon.mat-warn {
  color: #f44336
}

.mat-form-field-type-mat-native-select .mat-form-field-infix:after {
  color: #0000008a
}

.mat-input-element:disabled, .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix:after {
  color: #00000061
}

.mat-input-element {
  caret-color: #3f51b5
}

  .mat-input-element::placeholder {
      color: #0000006b
  }

  .mat-input-element::-moz-placeholder {
      color: #0000006b
  }

  .mat-input-element::-webkit-input-placeholder {
      color: #0000006b
  }

  .mat-input-element:-ms-input-placeholder {
      color: #0000006b
  }

.mat-form-field.mat-accent .mat-input-element {
  caret-color: #ff4081
}

.mat-form-field.mat-warn .mat-input-element, .mat-form-field-invalid .mat-input-element {
  caret-color: #f44336
}

.mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix:after {
  color: #f44336
}

.mat-list-base .mat-list-item, .mat-list-base .mat-list-option {
  color: #000000de
}

.mat-list-base .mat-subheader {
  color: #0000008a
}

.mat-list-base .mat-list-item-disabled {
  background-color: #eee;
  color: #00000061
}

.mat-list-option:hover, .mat-list-option:focus, .mat-nav-list .mat-list-item:hover, .mat-nav-list .mat-list-item:focus, .mat-action-list .mat-list-item:hover, .mat-action-list .mat-list-item:focus {
  background: rgba(0,0,0,.04)
}

.mat-list-single-selected-option, .mat-list-single-selected-option:hover, .mat-list-single-selected-option:focus {
  background: rgba(0,0,0,.12)
}

.mat-menu-panel {
  background: #fff
}

  .mat-menu-panel:not([class*=mat-elevation-z]) {
      box-shadow: 0 2px 4px -1px #0003,0 4px 5px #00000024,0 1px 10px #0000001f
  }

.mat-menu-item {
  background: transparent;
  color: #000000de
}

  .mat-menu-item[disabled], .mat-menu-item[disabled] .mat-menu-submenu-icon, .mat-menu-item[disabled] .mat-icon-no-color {
      color: #00000061
  }

  .mat-menu-item .mat-icon-no-color, .mat-menu-submenu-icon {
      color: #0000008a
  }

  .mat-menu-item:hover:not([disabled]), .mat-menu-item.cdk-program-focused:not([disabled]), .mat-menu-item.cdk-keyboard-focused:not([disabled]), .mat-menu-item-highlighted:not([disabled]) {
      background: rgba(0,0,0,.04)
  }

.mat-paginator {
  background: #fff
}

.mat-paginator, .mat-paginator-page-size .mat-select-trigger {
  color: #0000008a
}

.mat-paginator-decrement, .mat-paginator-increment {
  border-top: 2px solid rgba(0,0,0,.54);
  border-right: 2px solid rgba(0,0,0,.54)
}

.mat-paginator-first, .mat-paginator-last {
  border-top: 2px solid rgba(0,0,0,.54)
}

.mat-icon-button[disabled] .mat-paginator-decrement, .mat-icon-button[disabled] .mat-paginator-increment, .mat-icon-button[disabled] .mat-paginator-first, .mat-icon-button[disabled] .mat-paginator-last {
  border-color: #00000061
}

.mat-paginator-container {
  min-height: 56px
}

.mat-progress-bar-background {
  fill: #cbd0e9
}

.mat-progress-bar-buffer {
  background-color: #cbd0e9
}

.mat-progress-bar-fill:after {
  background-color: #3f51b5
}

.mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #fbccdc
}

.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #fbccdc
}

.mat-progress-bar.mat-accent .mat-progress-bar-fill:after {
  background-color: #ff4081
}

.mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f9ccc9
}

.mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f9ccc9
}

.mat-progress-bar.mat-warn .mat-progress-bar-fill:after {
  background-color: #f44336
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #3f51b5
}

.mat-progress-spinner.mat-accent circle, .mat-spinner.mat-accent circle {
  stroke: #ff4081
}

.mat-progress-spinner.mat-warn circle, .mat-spinner.mat-warn circle {
  stroke: #f44336
}

.mat-radio-outer-circle {
  border-color: #0000008a
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #3f51b5
}

.mat-radio-button.mat-primary .mat-radio-inner-circle, .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #3f51b5
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ff4081
}

.mat-radio-button.mat-accent .mat-radio-inner-circle, .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #ff4081
}

.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f44336
}

.mat-radio-button.mat-warn .mat-radio-inner-circle, .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #f44336
}

.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle, .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: #00000061
}

.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element, .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: #00000061
}

.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: #00000061
}

.mat-radio-button .mat-ripple-element {
  background-color: #000
}

.mat-select-value {
  color: #000000de
}

.mat-select-placeholder {
  color: #0000006b
}

.mat-select-disabled .mat-select-value {
  color: #00000061
}

.mat-select-arrow {
  color: #0000008a
}

.mat-select-panel {
  background: #fff
}

  .mat-select-panel:not([class*=mat-elevation-z]) {
      box-shadow: 0 2px 4px -1px #0003,0 4px 5px #00000024,0 1px 10px #0000001f
  }

  .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
      background: rgba(0,0,0,.12)
  }

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #3f51b5
}

.mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #ff4081
}

.mat-form-field.mat-focused.mat-warn .mat-select-arrow, .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #f44336
}

.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: #00000061
}

.mat-drawer-container {
  background-color: #fafafa;
  color: #000000de
}

.mat-drawer {
  background-color: #fff;
  color: #000000de
}

  .mat-drawer.mat-drawer-push {
      background-color: #fff
  }

  .mat-drawer:not(.mat-drawer-side) {
      box-shadow: 0 8px 10px -5px #0003,0 16px 24px 2px #00000024,0 6px 30px 5px #0000001f
  }

.mat-drawer-side {
  border-right: solid 1px rgba(0,0,0,.12)
}

  .mat-drawer-side.mat-drawer-end, [dir=rtl] .mat-drawer-side {
      border-left: solid 1px rgba(0,0,0,.12);
      border-right: none
  }

      [dir=rtl] .mat-drawer-side.mat-drawer-end {
          border-left: none;
          border-right: solid 1px rgba(0,0,0,.12)
      }

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: #0009
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #ff4081
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: #ff40818a
}

.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #ff4081
}

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #3f51b5
}

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: #3f51b58a
}

.mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #3f51b5
}

.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #f44336
}

.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: #f443368a
}

.mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #f44336
}

.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: #000
}

.mat-slide-toggle-thumb {
  box-shadow: 0 2px 1px -1px #0003,0 1px 1px #00000024,0 1px 3px #0000001f;
  background-color: #fafafa
}

.mat-slide-toggle-bar {
  background-color: #00000061
}

.mat-slider-track-background {
  background-color: #00000042
}

.mat-slider.mat-primary .mat-slider-track-fill, .mat-slider.mat-primary .mat-slider-thumb, .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #3f51b5
}

.mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: #fff
}

.mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: #3f51b533
}

.mat-slider.mat-accent .mat-slider-track-fill, .mat-slider.mat-accent .mat-slider-thumb, .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #ff4081
}

.mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: #fff
}

.mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: #ff408133
}

.mat-slider.mat-warn .mat-slider-track-fill, .mat-slider.mat-warn .mat-slider-thumb, .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #f44336
}

.mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: #fff
}

.mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: #f4433633
}

.mat-slider:hover .mat-slider-track-background, .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: #00000061
}

.mat-slider.mat-slider-disabled .mat-slider-track-background, .mat-slider.mat-slider-disabled .mat-slider-track-fill, .mat-slider.mat-slider-disabled .mat-slider-thumb, .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: #00000042
}

.mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: #0000001f
}

.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb, .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: #000000de
}

.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb, .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: #00000042
}

.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: #00000042;
  background-color: transparent
}

.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: #00000061
}

.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: #00000042
}

.mat-slider-has-ticks .mat-slider-wrapper:after {
  border-color: #000000b3
}

.mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right,rgba(0,0,0,.7),rgba(0,0,0,.7) 2px,transparent 0,transparent);
  background-image: -moz-repeating-linear-gradient(.0001deg,rgba(0,0,0,.7),rgba(0,0,0,.7) 2px,transparent 0,transparent)
}

.mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom,rgba(0,0,0,.7),rgba(0,0,0,.7) 2px,transparent 0,transparent)
}

.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover:not([aria-disabled]), .mat-step-header:hover[aria-disabled=false] {
  background-color: #0000000a
}

.mat-step-header:hover[aria-disabled=true] {
  cursor: default
}

@media (hover: none) {
  .mat-step-header:hover {
      background: none
  }
}

.mat-step-header .mat-step-label, .mat-step-header .mat-step-optional {
  color: #0000008a
}

.mat-step-header .mat-step-icon {
  background-color: #0000008a;
  color: #fff
}

.mat-step-header .mat-step-icon-selected, .mat-step-header .mat-step-icon-state-done, .mat-step-header .mat-step-icon-state-edit {
  background-color: #3f51b5;
  color: #fff
}

.mat-step-header.mat-accent .mat-step-icon {
  color: #fff
}

.mat-step-header.mat-accent .mat-step-icon-selected, .mat-step-header.mat-accent .mat-step-icon-state-done, .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #ff4081;
  color: #fff
}

.mat-step-header.mat-warn .mat-step-icon {
  color: #fff
}

.mat-step-header.mat-warn .mat-step-icon-selected, .mat-step-header.mat-warn .mat-step-icon-state-done, .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #f44336;
  color: #fff
}

.mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f44336
}

.mat-step-header .mat-step-label.mat-step-label-active {
  color: #000000de
}

.mat-step-header .mat-step-label.mat-step-label-error {
  color: #f44336
}

.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: #fff
}

.mat-stepper-vertical-line:before {
  border-left-color: #0000001f
}

.mat-horizontal-stepper-header:before, .mat-horizontal-stepper-header:after, .mat-stepper-horizontal-line {
  border-top-color: #0000001f
}

.mat-horizontal-stepper-header {
  height: 72px
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header, .mat-vertical-stepper-header {
  padding: 24px
}

.mat-stepper-vertical-line:before {
  top: -16px;
  bottom: -16px
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header:after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:before {
  top: 36px
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px
}

.mat-sort-header-arrow {
  color: #757575
}

.mat-tab-nav-bar, .mat-tab-header {
  border-bottom: 1px solid rgba(0,0,0,.12)
}

.mat-tab-group-inverted-header .mat-tab-nav-bar, .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0,0,0,.12);
  border-bottom: none
}

.mat-tab-label, .mat-tab-link {
  color: #000000de
}

  .mat-tab-label.mat-tab-disabled, .mat-tab-link.mat-tab-disabled {
      color: #00000061
  }

.mat-tab-header-pagination-chevron {
  border-color: #000000de
}

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: #00000061
}

.mat-tab-group[class*=mat-background-] > .mat-tab-header, .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none
}

.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: #c5cae94d
}

.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #3f51b5
}

.mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: #fff
}

.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: #ff80ab4d
}

.mat-tab-group.mat-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #ff4081
}

.mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: #fff
}

.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: #ffcdd24d
}

.mat-tab-group.mat-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #f44336
}

.mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: #fff
}

.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: #c5cae94d
}

.mat-tab-group.mat-background-primary > .mat-tab-header, .mat-tab-group.mat-background-primary > .mat-tab-link-container, .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #3f51b5
}

  .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
      color: #fff
  }

      .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
          color: #fff6
      }

  .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron, .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator:before, .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator:before, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator:before, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator:before {
      border-color: #fff
  }

  .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
      border-color: #fff;
      opacity: .4
  }

  .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element, .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
      background-color: #fff;
      opacity: .12
  }

.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: #ff80ab4d
}

.mat-tab-group.mat-background-accent > .mat-tab-header, .mat-tab-group.mat-background-accent > .mat-tab-link-container, .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #ff4081
}

  .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
      color: #fff
  }

      .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
          color: #fff6
      }

  .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron, .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator:before, .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator:before, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator:before, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator:before {
      border-color: #fff
  }

  .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
      border-color: #fff;
      opacity: .4
  }

  .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element, .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
      background-color: #fff;
      opacity: .12
  }

.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: #ffcdd24d
}

.mat-tab-group.mat-background-warn > .mat-tab-header, .mat-tab-group.mat-background-warn > .mat-tab-link-container, .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #f44336
}

  .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
      color: #fff
  }

      .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
          color: #fff6
      }

  .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron, .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator:before, .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator:before, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator:before, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator:before {
      border-color: #fff
  }

  .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
      border-color: #fff;
      opacity: .4
  }

  .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element, .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
      background-color: #fff;
      opacity: .12
  }

.mat-toolbar {
  background: #f5f5f5;
  color: #000000de
}

  .mat-toolbar.mat-primary {
      background: #3f51b5;
      color: #fff
  }

  .mat-toolbar.mat-accent {
      background: #ff4081;
      color: #fff
  }

  .mat-toolbar.mat-warn {
      background: #f44336;
      color: #fff
  }

  .mat-toolbar .mat-form-field-underline, .mat-toolbar .mat-form-field-ripple, .mat-toolbar .mat-focused .mat-form-field-ripple {
      background-color: currentColor
  }

  .mat-toolbar .mat-form-field-label, .mat-toolbar .mat-focused .mat-form-field-label, .mat-toolbar .mat-select-value, .mat-toolbar .mat-select-arrow, .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
      color: inherit
  }

  .mat-toolbar .mat-input-element {
      caret-color: currentColor
  }

.mat-toolbar-multiple-rows {
  min-height: 64px
}

.mat-toolbar-row, .mat-toolbar-single-row {
  height: 64px
}

@media (max-width: 599px) {
  .mat-toolbar-multiple-rows {
      min-height: 56px
  }

  .mat-toolbar-row, .mat-toolbar-single-row {
      height: 56px
  }
}

.mat-tooltip {
  background: rgba(97,97,97,.9)
}

.mat-tree {
  background: #fff
}

.mat-tree-node, .mat-nested-tree-node {
  color: #000000de
}

.mat-tree-node {
  min-height: 48px
}

.mat-snack-bar-container {
  color: #ffffffb3;
  background: #323232;
  box-shadow: 0 3px 5px -1px #0003,0 6px 10px #00000024,0 1px 18px #0000001f
}

.mat-simple-snackbar-action {
  color: #ff4081
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: #fff
}
